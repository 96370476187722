import { Text } from 'rsv8-components';
import styled from 'styled-components';
import {AnimatedCircle} from "rsv8-components";

export const Spinner = styled(AnimatedCircle)`
  margin-top: 12px;
` as any;

export const RedeemContainer = styled.div`
  width: 100%;
  height: 99%;
  text-align: center;
  align-content: center;
  align-items: center;
  align-self: center;
  vertical-align: middle;
  background-color: #ffffff;
  padding-left: 0px;
  padding-top: 50px;
  padding-bottom: 50px;
` as any;

export const MobileRedeemContainer = styled.div`
  width: 100%;
  height: 99%;
  padding-left: 0px;
  text-align: center;
  align-content: center;
  align-items: center;
  align-self: center;
  background-color: #ffffff;
  padding-top: 50px;
  padding-bottom: 50px;
` as any;

export const RedeemForm = styled.form`
  background-color: #ffffff;
  text-align: center;
  align-content: center;
  align-items: center;
  align-self: center;
` as any;

export const RedeemTable = styled.table`
  width: 100%;
` as any;

export const ResendInvitation = styled.div`
  display: inline-block;
` as any;

export const EnrolledAndActivated = styled.div`
  display: inline-block;
` as any;

export const UserNotEnrolled = styled.div`
  display: inline-block;

  @media only screen and (max-width: 780px) {
    margin: 25px 0 0;
  }
` as any;

export const TableRow = styled.tr`
  width: 60%;
` as any;

export const ImageCell = styled.td`
  background-color: #ffffff; /*#fafafa;*/
  text-align: center;
  align-content: left;
  align-self: left;
  vertical-align: top;
` as any;

export const MobileImageCell = styled.td`
  width: 100%;
  background-color: #ffffff; /*#fafafa;*/
  text-align: center;
  align-content: center;
  align-self: center;
  vertical-align: middle;
` as any;

export const MobileRedeemInfoCell = styled.td`
  padding: 10px;
  text-align: center;
` as any;

export const RedeemInfoCell = styled.td`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0px;
  padding-left: 0px;
  text-align: left;

  @media only screen and (max-width: 780px) {
    text-align: center;
    width: 140px;
  }
` as any;

export const RedeemInfoCellFrame = styled.td`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0px;
  padding-left: 35px;
  text-align: left;

  @media only screen and (max-width: 780px) {
    width: 140px;
  }
` as any;

export const RedeemInfoTable = styled.table`
  width: 287px;
  padding: 25px;
` as any;

export const MobileRedeemInfoTable = styled.table`
  width: 100%;
  text-align: center;
  padding: 50px;
` as any;

export const RedeemTypeText = styled(Text)`
  width: 159px;
  height: 30px;
  font-family: open-sans;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #373a36;
` as any;

export const EnrollTodayDescription = styled(Text)`
  width: 287px;
  height: 200px;
  font-family: open-sans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #515550;
  text-align: left;
  @media only screen and (max-width: 1024px) {
    width: 140px;
  }
  @media only screen and (max-width: 780px) {
    text-align: center;
    width: 110px;
  }
` as any;

export const RedeemTypeDescription = styled(Text)`
  width: 287px;
  height: 200px;
  font-family: open-sans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #515550;
  @media only screen and (max-width: 1024px) {
    width: 140px;
  }
  @media only screen and (max-width: 780px) {
    text-align: center;
    width: 110px;
  }
` as any;

export const learnMoreLink = styled(Text)`
  width: 287px;
  height: 200px;
  font-family: open-sans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #515550;
  @media only screen and (max-width: 1024px) {
    width: 140px;
  }
  @media only screen and (max-width: 780px) {
    text-align: center;
    width: 110px;
  }
` as any;

export const SubmitButton = styled.button`
  width: 287px;
  height: 60px;
  border-radius: 30px;
  background-color: #003595;
  margin: 0px;
  font-family: open-sans;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 2px;
  color: #ffffff;
  outline: none;
  border: 0;
  @media only screen and (max-width: 1024px) {
    width: 230px;
  }
` as any;

export const SubmitButtonDisabled = styled.button`
  width: 287px;
  height: 60px;
  border-radius: 30px;
  background-color: #818181;
  cursor: not-allowed;
  margin: 0px;
  font-family: open-sans;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 2px;
  color: #ffffff;
  outline: none;
  border: 0;
  @media only screen and (max-width: 1024px) {
    width: 230px;
  }
` as any;

export const MobileRedeemTypeDescription = styled(Text)`
  width: 170px;
  height: 200px;
  font-family: open-sans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #515550;
` as any;

export const MobileSubmitButton = styled.button`
  width: 170px;
  height: 60px;
  border-radius: 30px;
  outline: none;
  border: 0;
  background-color: #003595;
  margin: 0px;
  font-family: open-sans;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 2px;
  color: #ffffff;
` as any;

export const MobileSubmitButtonDisabled = styled.button`
  width: 170px;
  height: 60px;
  border-radius: 30px;
  outline: none;
  border: 0;
  background-color: #818181;
  cursor: not-allowed;
  margin: 0px;
  font-family: open-sans;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 2px;
  color: #ffffff;
` as any;

export const AmountHeader = styled(Text)`
  width: 50%;
  height: 25px;
  font-family: open-sans-bold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #373a36;
` as any;

export const AmountText = styled(Text)`
  width: 270px;
  height: 25px;
  font-family: open-sans-extrabold;
  font-size: 17px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #00b7f1;
` as any;

export const InsufficientAmountText = styled(Text)`
  width: 270px;
  height: 25px;
  font-family: open-sans;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.63;
  letter-spacing: normal;
  color: #9b1a2f;
` as any;

export const AmountInput = styled.input`
  width: 159px;
  height: 34px;
  border-radius: 3px;
  border: solid 1px rgba(81, 81, 81, 0.3);
  background-color: #ffffff;
  font-family: open-sans;
  text-align: left;
  input::-webkit-inner-spin-button {
    -webkit-appearance: inner-spin-button;
    display: inline-block;
    cursor: default;
    flex: 0 0 auto;
    align-self: stretch;
    -webkit-user-select: none;
    opacity: 0;
    pointer-events: none;
    -webkit-user-modify: read-only;
  }
` as any;

export const RedeemOptionContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;

  .redeem-option__title-mobile {
    display: none;
  }

  .redeem-option__title-desktop {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    color: #515550;
    margin-bottom: 20px;
  }

  .redeem-option__image {
    text-align: center;

    img {
      max-width: 100%;
      width: 70%;
    }
  }

  .redeem-option__label {
    font-size: 15px;
    color: #3f423e;
    margin-bottom: 8px;
  }

  .redeem-option__input-wrap {
    margin-bottom: 10px;
  }

  .redeem-option__total {
    margin: 15px 0;
    font-size: 17px;
    font-weight: 800;
    color: #00d3f7;
  }

  .redeem-option__warning {
    font-size: 10px;
    color: #a51c36;
    margin: 15px 0;
    min-height: 20px;
  }

  .redeem-option__notes {
    font-size: 15px;
    color: #3f423e;
    margin-bottom: 20px;
  }

  .redeem-option__btn {
    background-color: #003595;
    border-radius: 30px;
    color: white;
    cursor: pointer;
    font-size: 15px;
    letter-spacing: 2px;
    padding: 15px 17px;
    text-align: center;
    margin-top: 40px;
  }

  .redeem-option__btn__disabled {
    background-color: #818181;
    border-radius: 30px;
    color: white;
    cursor: not-allowed;
    font-size: 15px;
    letter-spacing: 2px;
    padding: 15px 17px;
    text-align: center;
    margin-top: 40px;
  }

  @media only screen and (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;

    .redeem-option__title-desktop {
      display: none;
    }

    .redeem-option__title-mobile {
      font-family: open-sans;
      font-size: 15px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: center;
      color: #373a36;
      display: block;
      padding-bottom: 13px;
    }
  }
` as any;
