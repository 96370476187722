import alconModule from './alconModule';
import Badge from './app/components/Badge';
import { DashboardBar, ProjectedRebateBar, RebateBar } from './app/components/Bar';
import RebateCalculator from './app/components/Calculator';
import CalculatorV2 from './app/components/Calculator/MarketShareCalculator';
import { DashBoardTitle, ProgramHeader } from './app/components/DashBoardTitle';
import { TierTemplateTable } from './app/components/DataTable';
import { DirectDeposit, PrepaidCard, ProductCredit, RebateCheck } from './app/components/Details';
import ForecastTable from './app/components/ForecastTable';
import Gauge from './app/components/Gauge';
import Goals from './app/components/Goals';
import MarketShare from './app/components/MarketShare';
import { CreateYourGoals, CurrentProgress, KeystoneProducts, MyGoals, MyProjections, ProductBreakdown } from './app/components/MyDashboard';
import CalculatorPermissionsRoute from './app/components/Permissions/CalculatorPermissionsRoute';
import DashboardPermissionsRoute from './app/components/Permissions/DashboardPermissionsRoute';
import PermissionsRoute from './app/components/Permissions/PermissionsRoute';
import RebatePermissionsRoute from './app/components/Permissions/RebatePermissionsRoute';
import RequireCalculatorPermissions from './app/components/Permissions/RequireCalculatorPermissions';
import RequireContentAffiliate from './app/components/Permissions/RequireContentAffiliate';
import RequireContentPermissions from './app/components/Permissions/RequireContentPermissions';
import RequireContentProgram from './app/components/Permissions/RequireContentProgram';
import RequireDashboardPermissions from './app/components/Permissions/RequireDashboardPermissions';
import RequirePermissions from './app/components/Permissions/RequirePermissions';
import RequireRebatePermissions from './app/components/Permissions/RequireRebatePermissions';
import { Products } from './app/components/Products';
import ProgramBannerSlider from './app/components/ProgramBannerSlider';
import ProgramDetailsLink from './app/components/ProgramDetailsLink';
import RebateHistory from './app/components/RebateHistory';
import RedemptionHistory from './app/components/Redeem';
import ShipToLocations from './app/components/ShipToLocations';
import {
  UserMetricByYear,
  UserMetricChangeForTimePeriod,
  UserMetricChartByYear,
  UserMetricForTimePeriod,
  UserMetricFromDate,
} from './app/components/UserAccessMetric';
import UserManagement from './app/components/UserManagement';
import { orgAttributes, updateCard } from './app/components/magnifeye-account';
import { rebateCards } from './app/components/rebateoptions';

export {
  Badge,
  CalculatorPermissionsRoute,
  CalculatorV2,
  CreateYourGoals,
  CurrentProgress,
  DashBoardTitle,
  DashboardBar,
  DashboardPermissionsRoute,
  DirectDeposit,
  ForecastTable,
  Gauge,
  Goals,
  KeystoneProducts,
  MarketShare,
  MyGoals,
  MyProjections,
  PermissionsRoute,
  PrepaidCard,
  ProductBreakdown,
  ProductCredit,
  Products,
  ProgramBannerSlider,
  ProgramDetailsLink,
  ProgramHeader,
  ProjectedRebateBar,
  RebateBar,
  RebateCalculator,
  RebateCheck,
  RebateHistory,
  RebatePermissionsRoute,
  RedemptionHistory,
  RequireCalculatorPermissions,
  RequireContentAffiliate,
  RequireContentPermissions,
  RequireContentProgram,
  RequireDashboardPermissions,
  RequirePermissions,
  RequireRebatePermissions,
  ShipToLocations,
  TierTemplateTable,
  UserManagement,
  UserMetricByYear,
  UserMetricChangeForTimePeriod,
  UserMetricChartByYear,
  UserMetricForTimePeriod,
  UserMetricFromDate,
  alconModule,
  orgAttributes,
  rebateCards,
  updateCard,
};
