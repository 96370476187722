import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Content } from 'rsv8-components';
import { assetService } from 'xcel-asset-service';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getRedeemOptions } from '../../redux/actions';
import { redeemOptionSelector } from '../../redux/selectors';
import * as styles from './styles/';

interface ComponentProps {
  redeemOptions: any;
}

const createRebateCards = (data) => {
  return data.map((element: any, index: any) => {
    var cursor = null;
    if (element.canRedeem) {
      cursor = 'pointer';
    }
    return (
      <styles.ProductCardDiv
        cursor={cursor}
        onClick={() => {
          if (element.canRedeem) {
            window.location.assign('/redeem/' + element.id);
          }
        }}
        key={index}
        style={{ margin: '10px 0 !important' }}
      >
        <styles.ProductCardBody themeVariation="rebatoptionscard">
          <styles.CardImage backgroundImage={assetService.getImage('content/' + element.imageUrlLarge)} />
          <div>
            <h3 style={{ fontFamily: 'open-sans-bold', color: '#505d6f', fontSize: '24px', textAlign: 'center' }}>
              {element.name}
            </h3>
            <Content themeVariation="rebateoptionscards" content={{ content: element.description }} />
          </div>
        </styles.ProductCardBody>
      </styles.ProductCardDiv>
    );
  });
};
const rebateCard: React.SFC<ComponentProps> = ({ redeemOptions, ...rest }) => {
  const [loading, setLoading] = useState(true);
  useEffect(
    () => {
      if (!redeemOptions) {
        return;
      }
      setLoading(false);
    },
    [redeemOptions]
  );

  return (
    <div {...rest}>
      {loading && <h3>Loading</h3>}
      {!loading &&
        redeemOptions && <Container themeVariation="rebate-option-cards">{createRebateCards(redeemOptions)}</Container>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  redeemOptions: redeemOptionSelector.selectMany(state)
});

export default register('alcon-information/rebateCard')(
  connect(mapStateToProps),
  withApiDataProvider(getRedeemOptions, redeemOptionSelector),
  withTheme(),
  withContent()
)(rebateCard);
